// DOM node to mount on
const root = document.getElementById("rebla-list");
if (!root) {
  throw Error('Rebla list element "rebla-list" is missing');
}

// Create search params to pass to the list widget
// with configs from the script attributes
const params = new URLSearchParams();
["baseUrl", "projectId", "token"].forEach((attr) => {
  const config = document?.currentScript?.getAttribute(attr);
  if (!config) {
    throw Error(`Rebla list config "${attr}" is missing`);
  }
  params.append(attr, config);
});

// Append the referrer and the location to the search params
params.append("location", document?.location?.href);
params.append("referrer", document?.referrer);

// The iframe source points to the widget URL
// along with the search params configuration
const src = `${params.get("baseUrl")}/projects/${params.get(
  "projectId"
)}/list-widget?${params.toString()}`;

root.innerHTML = `<iframe
    id="rebla-list"
    name="rebla-list"
    loading="lazy"
    style="height: 80vh; width: 1px; min-width: 100%;"
    frameborder="0"
    src=${src}
  />`;
